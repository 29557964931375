@mixin plain-link($text-color: $body-color) {
  color: $text-color;
  text-decoration: none;
  @include hover() {
    color: $text-color;
    text-decoration: none;
  }
}
.plain-link {
  @include plain-link();
}

@mixin link() {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;
  cursor: pointer;
  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}
.link {
  @include link();
}
